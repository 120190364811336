@import 'vars';

.input {
  border-radius: 4px !important;
  height: 50px !important;
  font-size: 16px !important;
  border-width: 1px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  border-color: $mid-grey !important;
  box-shadow: none !important;
  border-style: solid;

  &:hover {
    border-color: darken($mid-grey, 5%) !important;
  }

  &::placeholder {
    color: $dark-grey;
  }

  &-continue {
    position: relative;
    display: flex;
    align-items: center;

    > input {
      padding-right: 55px !important;
    }

    > button {
      position: absolute;
      right: 5px;
      min-height: 40px !important;
      height: 40px !important;
      min-width: 40px !important;
      width: 40px !important;
    }

    &--small {
      > input {
        padding-left: 10px !important;
        padding-right: 45px !important;
      }

      > button {
        min-height: 30px !important;
        height: 30px !important;
        min-width: 30px !important;
        width: 30px !important;
      }
    }
  }

  &:focus {
    border-color: $primary !important;
    box-shadow: 0 0 0 1px $primary !important;
  }

  &:disabled {
    border-color: whitesmoke !important;
    color: #939393 !important;
  }

  &--small {
    font-size: 13px !important;
    height: 40px !important;
  }
}

textarea.input {
  resize: none;
  height: 100px;
  text-align: unset;
  min-height: 100px;
  padding-top: 15px;
}