$auto: auto;
$directions: 't', 'b', 'l', 'r';
$css-property-map: ('t': margin-top, 'b': margin-bottom, 'l': margin-left, 'r': margin-right);
$sizes: 0, 5, 10, 20, 30, 40, 50, 60, 100, $auto;

@each $direction in $directions {
  @each $size in $sizes {
    .m#{$direction}-#{$size} {
      @if $size == $auto {
        #{map-get($css-property-map, $direction)}: $auto;
      } @else {
        #{map-get($css-property-map, $direction)}: #{$size}px;
      }
    }
  }
}
