.who-we-are {
  width: 100%;
  display: flex;

  &__left-col,
  &__right-col {
    width: 50%;
    flex: 1;
  }

  &__right-col svg {
    width: 100%;
    height: auto !important;
  }

  @media only screen and (max-width: 1000px) {
    &__right-col {
      display: none;
    }

    &__left-col {
      width: 100%;
    }
  }

  &__text {
    margin-bottom: 30px;
  }

  &__tag {
    width: 80px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border-radius: 6px;
    font-weight: 500;

    &:first-of-type:not(:last-of-type) {
      margin-right: 10px;
    }
  }

  &__profile-image img {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }

  &__accordion {
    &__tags {
      margin-left: 30px;
      display: flex;
    }

    &__organiser {
      display: flex;
      align-items: center;

      > p {
        width: 60px;
      }

      &:not(:last-of-type) {
        margin-bottom: 20px;
      }
    }
  }
}