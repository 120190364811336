@import '~react-image-gallery/styles/scss/image-gallery.scss';
@import '~rodal/lib/rodal.css';

// Custom scss files
@import './vars';
@import './_typography';
@import './_overrides';
@import './_animations';
@import './utils/_index';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html,
#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

* {
  outline-color: $text;
  box-sizing: border-box;
}

[data-whatintent='mouse'] *:focus,
[data-whatintent='touch'] *:focus {
  outline: none;
}


