@import 'vars';

@mixin scale-hover {
  cursor: pointer;
  will-change: transform;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
}

@mixin header-padding {
  padding-left: 40px;
  padding-right: 40px;

  @media only screen and (max-width: $mobile-width) {
    padding: 20px;
  }

  @media only screen and (min-width: $large-width) {
    padding: 80px;
  }
}

@mixin app-padding {
  padding: 40px;

  @media only screen and (max-width: $mobile-width) {
    padding: 20px;
  }

  @media only screen and (min-width: $large-width) {
    padding: 80px;
  }
}
