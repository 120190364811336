@import 'vars';

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

.get-in-touch {
  display: flex;
  align-items: flex-start;
  max-width: 1200px;
  margin: auto;

  > * {
    width: 50% !important;
    height: auto !important;

    @media only screen and (max-width: 1000px) {
      width: 100% !important;
    }

    &:first-child {
      @media only screen and (max-width: 1000px) {
        display: none;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-left: 20px;

    @media only screen and (max-width: 1000px) {
      margin-left: 0;
    }

    &__arrow {
      width: 20%;
      height: auto;
      margin: 40px auto;
      transform: rotate(0deg);

      .draw-arrow {
        stroke-width: 5;
        stroke: $primary;
        fill: none;
        stroke-dasharray: 400;
        stroke-dashoffset: 400;
        animation-duration: 2.0s;
        animation-fill-mode: forwards;
        animation-name: draw;
        animation-delay: 1.5s;

        &.tail-1 {
          animation-delay: 2s;
        }

        &.tail-2 {
          animation-delay: 2.2s;
        }
      }
    }

    &--centred {
      margin: auto;
    }

    > form {
      display: flex;
      flex-direction: column;
      margin-top: 30px;

      > *:not(:last-child) {
        margin-bottom: 30px;
      }

      > button {
        margin-left: auto;
      }
    }
  }
}
