.home {
  display: flex;
  flex-direction: column;
  padding-right: calc(30vw + 40px);

  &__subtitle {
    margin-bottom: 40px;
    max-width: 1200px;
  }

  @media only screen and (max-width: 1000px) {
    padding-right: 0;
  }

  &__top-roping-image {
    position: fixed;
    top: 0;
    right: 0;
    width: 30vw;
    height: auto;
    z-index: 2;
    pointer-events: none;

    @media only screen and (max-width: 1050px) {
      display: none;
    }
  }

  &__bouldering-image {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    height: 45vh;
    width: auto;

    @media only screen and (max-width: 1000px) {
      margin: auto;
    }

    @media only screen and (max-width: 600px) {
      width: 100%;
      height: auto;
    }
  }
}