@import 'vars';

.accordion {
  width: calc(100% + 40px);
  margin-left: -20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  transition: border 300ms ease-in-out;
  border-radius: 8px;
  border: 2px solid transparent;

  @media only screen and (max-width: 1000px) {
    width: calc(100% + 20px);
    margin-left: -10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }

  &--open {
    border-color: $mid-grey;
  }

  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    height: 80px;

    &__icon {
      width: 40px;
      min-width: 40px;
      height: 40px;
      min-height: 40px;
      border-radius: 50%;
      margin-left: auto;
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
}