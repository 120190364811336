// Colours
$primary: #ff4e75;
$secondary: #faf3ee;
$accent: #FFB2B3;

$yellow: #F4C51B;

$danger: #FF453A;
$success: #33C759;

$text: #112035;

$light-grey: #F4F4F4;
$mid-grey: #E0E0E0;
$dark-grey: #BBBBBB;


// Sizes
$mobile-width: 700px;
$large-width: 1600px;