$sizes: 10, 20, 30, 40;

.next-to {
  display: flex;
  align-items: center;
}

@each $size in $sizes {
  .next-to-spaced-#{$size} {
    @extend .next-to;

    > *:not(:last-child) {
      margin-right: #{$size}px;
    }
  }
}

.grow {
  flex: 1;
}
