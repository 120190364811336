.events-and-sessions {
  display: flex;
  width: calc(100vw + 80px);
  margin-left: -40px;
  padding-right: 20vw;
  align-items: flex-start;

  @media only screen and (max-width: 800px) {
    width: 100%;
    margin-left: 0;
  }

  @media only screen and (max-width: 1000px) {
    padding-right: 0;
    width: calc(100vw + 40px);
  }

  &__info-block {
    margin-bottom: 80px;

    &__small {
      font-size: 14px !important;
    }

    a {
      text-decoration: underline;
    }

    &__row {
      display: flex;
      align-items: center;

      > *:first-child {
        margin-right: 10px;
      }
    }
  }

  &__content {
    margin-left: 50px;
    margin-right: 50px;
    padding-left: 15vw;

    @media only screen and (max-width: 1000px) {
      margin-right: 0;
    }

    @media only screen and (max-width: 800px) {
      margin-left: 0px;
      padding-left: 0px;
    }
  }

  > svg {
    height: auto;

    @media only screen and (max-width: 800px) {
      display: none;
    }

    &:first-of-type {
      width: 15vw;
      position: fixed;
      left: 0;
    }

    &:last-of-type {
      position: fixed;
      top: 40px;
      right: 40px;
      width: 20vw;
      z-index: 2;

      @media only screen and (max-width: 1000px) {
        display: none;
      }
    }
  }
}