@import 'vars';
@import 'mixins';

.header {
  @include header-padding();
  display: flex;
  align-items: center;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  height: 125px;
  width: 100vw;
  z-index: 2;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  @media only screen and (max-width: $mobile-width) {
    height: 100px;
  }

  &--mobile {
    > div {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 2;
    }
  }

  &__logo {
    padding: 0;
    border: 0;
    background: none;
    cursor: pointer;

    > svg {
      width: 150px;
      height: auto;

      @media only screen and (max-width: 800px) {
        width: 100px;
      }
    }
  }

  &__links {
    display: flex;
    align-items: center;
    margin-left: 40px;

    &__link {
      &--grow {
        -webkit-backface-visibility: hidden;
        -webkit-transform: translateZ(0) scale(1.0, 1.0);
        will-change: auto;
        transition: transform 0.2s ease-in-out;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    &--mobile {
      display: flex !important;
      flex-direction: column;

      > *:not(:last-child) {
        margin-bottom: 30px;
      }
    }

    > *:not(:last-child) {
      margin-right: 40px;
    }

    &__link {
      font-weight: 500;

      &--selected {
        color: $primary;
        pointer-events: none;

        > i {
          color: $primary !important;
        }
      }
    }

    &--mobile &__link {
      font-size: 20px;
    }
  }
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 26px;
  height: 20px;
  right: 20px;
  top: 42px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: $primary;
}

/* Color/shape of close button cross */
.bm-cross {
  background: $primary;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #fff;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}