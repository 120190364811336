.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background: white;
  padding: 40px;
  z-index: 2;

  > svg {
    width: 200px;
    height: auto;

      @media only screen and (max-width: 1000px) {
        width: 150px;
      }
  }

  &__animation {
    max-width: 600px;
    flex: 1;

    @media only screen and (max-width: 800px) {
      max-height: 50vh;
    }

  }

  &__content {
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin-top: 40px;

    @media only screen and (max-width: 800px) {
      flex-direction: column;
    }

    &__help {
      margin-left: 40px;
      max-width: 40vw;

      @media only screen and (max-width: 800px) {
        display: flex;
        flex-direction: column;
        max-width: unset;
        margin-left: 0;
        justify-content: center;
        text-align: center;

        > button {
          @media only screen and (max-width: 800px) {
            margin: auto;
          }

          @media only screen and (max-width: 600px) {
            width: 100%;
          }
        }
      }
    }
  }
}