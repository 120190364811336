@import 'vars';
@import 'mixins';

.core-layout {
  @include app-padding;

  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 125px !important;

  @media only screen and (max-width: $mobile-width) {
    padding-top: 100px !important;
  }

  &__content {
    margin-top: 20px;
    flex: 1;
    display: flex;
    padding-bottom: 40px;

    > *:first-child {
      width: 100%;
    }

    @media only screen and (max-width: 800px) {
      padding-bottom: 20px;
    }
  }
}